<template>
    <div>
        <div>
            <div>
                <el-button type="primary" @click="addBtn" size="mini">添加商品类型</el-button>
            </div>
        </div>
        <br>
        <el-table  :data="good_class_list"   border  style="width: 100%">
            <el-table-column   align="center"    prop="good_class_name"    label="类型名称"></el-table-column>
            <el-table-column label="添加时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						    {{scope.row.add_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
            <el-table-column label="更新时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						    {{scope.row.update_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
            <el-table-column  label="操作"  fixed="right"  align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="edit(scope)">编辑</el-button>
                    <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog
                :title="title"
                :visible.sync="show_editor_class"
                width="700px"
        >
            <el-form label-width="120px">
                <el-form-item label="类型名称">
                    <el-input v-model="goodData.good_class_name" style="width: 300px"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editor_class = false">取 消</el-button>
                    <el-button type="primary" @click="addClass">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                title:'添加类型',
                good_class_list:[],
                show_editor_class:false,
                goodData:{
                  id:'',
                   good_class_name:"",//添加的名称
                }

            }
        },
        mounted(){
            document.title='商品分类'

            this.getClassList()

        },
        methods:{
            edit(el){
               let row = el.row
               this.goodData.id = row.id
               this.goodData.good_class_name = row.good_class_name
               this.show_editor_class = true
            },
            del(el){
                let _this=this
                if(confirm('确定删除')){
                    var index = el.$index
                    var id=el.row.id
                    this.$post('/pc/goods/delGoodClass',{id:id},function (res) {
                        let result = res.data
                        if(result.code==200){
                            _this.good_class_list.splice(index,1)
                            _this.page_totle--
                            _this.$message.success(result.msg)
                        }
                    })
                }
            },
            addBtn(){
                this.show_editor_class=true
                this.title='添加类型'
                this.goodData = {
                  id:'',
                   good_class_name:"",//添加的名称
                }
            },
            getClassList(){
                let _this=this
                this.$post('/pc/goods/getGoodsClass',{},function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.good_class_list=result.data
                        _this.$message.success(result.msg)
                    }
                    if(result.code==401){
                       _this.$router.push({path:'/'})
                    }
                })
            },
            addClass(){

                let _this=this

                if(this.good_class_list.length>=10) return this.$message.error('最多添加10条')

                this.$post('/pc/goods/setGoodsClass',_this.goodData,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.show_editor_class=false
                        _this.$message.success(result.msg)
                        _this.getClassList()
                    }
                })
            },
        },
    }
</script>
